/**
 * Forms
 */

// translation variables

let flotingLabel = $('.js-floting-input');

if ($('html').attr('lang') == 'en-US') {

    var messages = {
        birthDate: {
            valid: 'Please enter a valid birth date.'
        },
        over19: {
            valid: 'You must be over 19 years old.'
        },
        over18: {
            valid: 'You must be over 18 years old.'
        },
        over16: {
            valid: 'You must be over 16 years old.'
        },
        min18: {
            required: 'Please enter an age over 18'
        },
        min19: {
            required: 'Please enter an age over 18'
        },
        select: {
            required: 'Please select an option.'
        },
        phone: {
            minlength: 'Please enter 10 digits.',
            maxlength: 'Please enter 10 digits.'
        },
        zipcode: {
            valid: '"Please enter a valid zip code."'
        }
    };

} else if ($('html').attr('lang') == 'es-US') {


    $.extend($.validator.messages, {
        required: "Este campo es obligatorio.",
        remote: "Por favor, rellena este campo.",
        email: "Por favor, escribe una dirección de correo válida.",
        url: "Por favor, escribe una URL válida.",
        date: "Por favor, escribe una fecha válida.",
        dateISO: "Por favor, escribe una fecha (ISO) válida.",
        number: "Por favor, escribe un número válido.",
        digits: "Por favor, escribe sólo dígitos.",
        creditcard: "Por favor, escribe un número de tarjeta válido.",
        equalTo: "Por favor, escribe el mismo valor de nuevo.",
        extension: "Por favor, escribe un valor con una extensión aceptada.",
        maxlength: $.validator.format("Por favor, no escribas más de {0} caracteres."),
        minlength: $.validator.format("Por favor, no escribas menos de {0} caracteres."),
        rangelength: $.validator.format("Por favor, escribe un valor entre {0} y {1} caracteres."),
        range: $.validator.format("Por favor, escribe un valor entre {0} y {1}."),
        max: $.validator.format("Por favor, escribe un valor menor o igual a {0}."),
        min: $.validator.format("Por favor, escribe un valor mayor o igual a {0}."),
        nifES: "Por favor, escribe un NIF válido.",
        nieES: "Por favor, escribe un NIE válido.",
        cifES: "Por favor, escribe un CIF válido."
    });

    var messages = {
        birthDate: {
            valid: 'Por favor, ingresa una fecha válida de nacimiento.'
        },
        over19: {
            valid: 'Debes ser mayor de 19 años.'
        },
        over18: {
            valid: 'Debes ser mayor de 18 años.'
        },
        over16: {
            valid: 'Debes ser mayor de 16 años.'
        },
        min18: {
            required: 'Por favor, ingrese una edad mayor o igual a 18'
        },
        min19: {
            required: 'Por favor, ingrese una edad mayor o igual a 18'
        },
        select: {
            required: 'Por favor, selecciona una opción.'
        },
        phone: {
            minlength: 'Por favor, ingresa 10 digitos.',
            maxlength: 'Por favor, ingresa 10 digitos.'
        },
        zipcode: {
            valid: '"Por favor, ingresa un código postal válido."'
        }
    }
}


var MyApp = {
    form: function () {}
}


$(function () {

    if ($('.js-form').length) {
        $.validator.methods.email = function (value, element) {
            return this.optional(element) || /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
        };

        $.validator.addMethod('birthDate', function (value, element) {
            return this.optional(element) || /^((0?[1-9])|1[012])\/(([0-2][0-9])|([1-9])|(3[0-1]))\/(19|20)[0-9]{2}$/.test(value);
        }, messages.birthDate.valid);

        $.validator.addMethod('over18', function (value, element) {
            var splitUserDOB = value.split('/');
            var DOB = new Date(splitUserDOB[2] + '/' + splitUserDOB[0] + '/' + splitUserDOB[1]);
            currentDate = new Date();

            if (currentDate.getFullYear() - DOB.getFullYear() < 18) {
                return this.optional(element) || false;
            } else if (currentDate.getFullYear() - DOB.getFullYear() == 18) {
                if (currentDate.getMonth() < DOB.getMonth()) {
                    return this.optional(element) || false;
                } else if (currentDate.getMonth() == DOB.getMonth()) {
                    if (currentDate.getDate() < DOB.getDate()) {
                        return this.optional(element) || false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }, messages.over18.valid);
        $.validator.addMethod('over19', function (value, element) {
            var splitUserDOB = value.split('/');
            var DOB = new Date(splitUserDOB[2] + '/' + splitUserDOB[0] + '/' + splitUserDOB[1]);
            currentDate = new Date();

            if (currentDate.getFullYear() - DOB.getFullYear() < 18) {
                return this.optional(element) || false;
            } else if (currentDate.getFullYear() - DOB.getFullYear() == 18) {
                if (currentDate.getMonth() < DOB.getMonth()) {
                    return this.optional(element) || false;
                } else if (currentDate.getMonth() == DOB.getMonth()) {
                    if (currentDate.getDate() < DOB.getDate()) {
                        return this.optional(element) || false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }, messages.over18.valid);

        $.validator.addMethod('over16', function (value, element) {
            var splitUserDOB = value.split('/');
            var DOB = new Date(splitUserDOB[2] + '/' + splitUserDOB[0] + '/' + splitUserDOB[1]);
            currentDate = new Date();
            if (currentDate.getFullYear() - DOB.getFullYear() < 16) {
                return this.optional(element) || false;
            } else if (currentDate.getFullYear() - DOB.getFullYear() == 16) {
                if (currentDate.getMonth() < DOB.getMonth()) {
                    return this.optional(element) || false;
                } else if (currentDate.getMonth() == DOB.getMonth()) {
                    if (currentDate.getDate() < DOB.getDate()) {
                        return this.optional(element) || false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }, messages.over16.valid);

        $.validator.addMethod('under100', function (value, element) {
            const currentYear = new Date().getFullYear();
            value = currentYear - (value.substr(value.length - 4));
            if (value <= 100) {
                return true;
            } else {
                return this.optional(element) || false;
            }
        }, messages.birthDate.valid);

        $('.js-form').each(function () {
            $(this).validate({
                errorClass: 'js-form-error',
                validClass: 'js-form-valid',
                errorPlacement: function (error, element) {
                    if (element.attr('type') == 'radio' || element.attr('name') == 'checkbox') {
                        let errorContainer = element.closest('.c-quote-product__radio').find('.js-error-radio-checkbox');
                        //console.log(errorContainer);
                        error.appendTo(errorContainer);
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: function (form) {
                    $(form).find('button[type=submit]').addClass('is-disabled');
                    if ($(form).hasClass('form--hippo')) {
                        $('.c-process-form').fadeOut();
                        $('.c-quote-product').fadeOut(function () {
                            $('.c-hippo-transition').fadeIn(function () {
                                $('.c-hippo-transition').attr('tabindex', -1).focus();
                            });
                        });
                    }
                    if ($(form).hasClass('ajax-quote-form-new')) {
                        //console.log(form);
                        var type_insurance = $(form).find("input[type='radio'][name='type_insurance']:checked").val();
                        if (type_insurance == null) {
                            type_insurance = $(form).find('select[name="type_insurance"]').val();
                        }
                        var zipcode = $(form).find('input[name="zipcode"]').val();
                        if (type_insurance == null) {
                            type_insurance = $(form).find('input[name="type_insurance"]').val();
                        }
                        url_site = $('#url_site').val();
                        if (typeof url_site == 'undefined' || url_site == null) {
                            var url_site = '';
                        }
                        //console.log(type_insurance);
                        //console.log(zipcode);

                        //console.log(2);
                        var form2 = document.createElement("form");
                        var element1 = document.createElement("input");
                        var element2 = document.createElement("input");
                        form2.style.cssText = 'opacity: 0; position: absolute; z-index: -1; bottom: 0;';
                        form2.method = "GET";
                        form2.action = url_site + "/process-rater/";
                        element1.value = zipcode;
                        element1.name = "zipcode";
                        form2.appendChild(element1);
                        element2.value = type_insurance;
                        element2.name = "type_insurance";
                        form2.appendChild(element2);
                        document.body.appendChild(form2);
                        
                        //console.log("1");
                        form2.submit();
                    } else {
                        //console.log("2");
                        form.submit();
                    }
                }
            });
        });

        if ($('.js-valid-zipcode').length) {
            $('.js-valid-zipcode').each(function (index, value) {
                $(this).rules('add', {
                    minlength: 3,
                    maxlength: 5,
                    remote: {
                        url: 'https://www.confiejarvis.com/thor/javascript?',
                        data: {
                            zipcode: function () {
                                return $(value).val();
                            }
                        },
                        dataFilter: function (data) {
                            if (data === "1") {
                                return '"true"';
                            }
                            return messages.zipcode.valid;
                        }
                    }
                });
            });
        }

        if ($('.js-valid-select').length) {
            $('.js-valid-select').each(function () {
                $(this).rules('add', {
                    messages: {
                        required: messages.select.required
                    }
                });
            });
        }

        if ($('.js-valid-phone').length) {
            $('.js-valid-phone').each(function () {
                $(this).rules('add', {
                    required: true,
                    minlength: 12,
                    maxlength: 12,
                    messages: {
                        minlength: messages.phone.minlength,
                        maxlength: messages.phone.maxlength
                    }
                });
            });
        }

        if ($('.js-valid-dob').length) {
            $('.js-valid-dob').each(function () {
                $(this).rules('add', {
                    birthDate: true,
                    under100: true,
                    over18: true
                });
            });
        }

        if ($('.js-valid-age').length) {
            $('.js-valid-age').each(function () {
                $(this).rules('add', {
                    required: true,
                    minlength: 2,
                    maxlength: 2,
                    min: 18,
                    max: 99,
                    messages: {
                        minlength: messages.over18.required,
                        maxlength: messages.over18.required,
                        min: messages.over18.required,
                        max: messages.over18.required
                    }
                });
            });
        }

        if ($('.js-valid-age-19').length) {
            $('.js-valid-age-19').each(function () {
                $(this).rules('add', {
                    required: true,
                    minlength: 2,
                    maxlength: 2,
                    min: 19,
                    max: 99,
                    messages: {
                        minlength: messages.over19.required,
                        maxlength: messages.over19.required,
                        min: messages.over19.required,
                        max: messages.over19.required
                    }
                });
            });
        }


        if ($('.min18').length) {
            $('.min18').each(function () {
                $(this).rules('add', {
                    min: 18,
                    messages: {
                        required: messages.min18.required
                    }
                });
            });
        }
        if ($('.min19').length) {
            $('.min19').each(function () {
                $(this).rules('add', {
                    min: 19,
                    messages: {
                        required: messages.min19.required
                    }
                });
            });
        }
    }

    // Floating labels

    if (flotingLabel.length) {


        flotingLabel.on('focus', function () {
            $(this).addClass('has-focus').prev('label').addClass('is-floating');
        });

        flotingLabel.on('blur', function () {
            $(this).removeClass('has-focus');
            if ($(this).val() == '') {
                $(this).prev('label').removeClass('is-floating')
            }
        });
    }

    // Conctact type

    if ($('.js-contact-type').length) {
        $('.js-contact-type').on('change', function () {
            let target = $(this).val();
            $('.c-quote-product__item.is-active').removeClass('is-active').addClass('is-hidden');
            $('.c-quote-product__input[data-contact-type]').val('').prop('required', false).prop('disabled', true)
            if (target == 'phone' || target == 'mail') {
                $('.c-quote-product__input[data-contact-type="' + target + '"]').prop('required', true).prop('disabled', false).closest('.c-quote-product__item').removeClass('is-hidden').addClass('is-active');
            }
        });
    }
});

$(window).on('pageshow', function () {
    //console.log('test');
    // esta clase falta .js-submit-button en los botones
    let submitButtonDisabled = $('button.is-disabled');
    if (flotingLabel.val() != '') {
        //console.log(flotingLabel.val());
        flotingLabel.addClass('has-focus').prev('label').addClass('is-floating');
    }

    if (submitButtonDisabled.length) {
        //console.log('disabled');
        submitButtonDisabled.removeClass('is-disabled')
    }
});