"use strict";

/**
 * Llamar a la API de Google en las páginas donde se utilizará el autocomplete,
 * y agregar como clase 'ga-places-autocomplete' al campo de dirección
 *
 *
 * <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBqLNiTFlveyp2pC266ZAB0hSsWQrANpLE&amp;callback=initAutocomplete&amp;libraries=places" defer></script>
 *
 *
 */
var el_autocomplete = []; //google.maps.event.addDomListener(window,initAutocomplete);

function initAutocomplete() {
    if (jQuery(".ga-places-autocomplete").length > 0) {
        var input = document.getElementsByClassName('ga-places-autocomplete');

        if (input.length > 0) {
            for (i = 0; i < input.length; i++) {
                el_autocomplete = new google.maps.places.Autocomplete(input[i], {
                    type: ['formatted_address'],
                    componentRestrictions: {
                        'country': ['US']
                    },
                    field: ['place_id', 'geometry', 'name']
                });
                google.maps.event.addListener(el_autocomplete, 'place_changed', function () {
                    var place = el_autocomplete.getPlace();
                    console.log(place); //console.log("place change");

                    var city, state, client_apt, zipcode, street_route, state_short;

                    for (var i = 0; i < place.address_components.length; i++) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] == "postal_code") {
                                document.getElementById('zipcode').innerHTML = place.address_components[i].long_name;
                                jQuery('#zipcode').text(place.address_components[i].long_name);
                                jQuery('#zipcode').val(place.address_components[i].long_name);
                                zipcode = place.address_components[i].long_name;
                            }

                            if (place.address_components[i].types[j] == "street_number") {
                                client_apt = place.address_components[i].short_name;
                            }

                            if (place.address_components[i].types[j] == "route") {
                                street_route = place.address_components[i].long_name;
                                console.log(street_route);
                            }

                            if (place.address_components[i].types[j] == "locality") {
                                city = place.address_components[i].short_name; //jQuery('#zipcode').text(place.address_components[i].long_name);
                                //jQuery('#zipcode').val(place.address_components[i].long_name);
                            }

                            console.log(city == undefined);

                            if (city == undefined) {
                                if (place.address_components[i].types[j] == "administrative_area_level_3") {
                                    city = place.address_components[i].short_name;
                                }
                            }
                            if (city == undefined) {
                                if (place.address_components[i].types[j] == "administrative_area_level_2") {
                                    city = place.address_components[i].short_name;
                                }
                            }
                            if (place.address_components[i].types[j] == "administrative_area_level_1") {
                                state = place.address_components[i].long_name;
                                state_short = place.address_components[i].short_name; //jQuery('#zipcode').text(place.address_components[i].long_name);
                                //jQuery('#zipcode').val(place.address_components[i].long_name);
                            }
                        }
                    } //jQuery('#client_apt').text(client_apt);
                    //jQuery('#client_apt').val(client_apt);


                    jQuery('#form_cityState').text(city + ', ' + state);
                    jQuery('#form_cityState').val(city + ', ' + state);
                    jQuery("#full_address").val(client_apt + " " + street_route);
                    jQuery("#full_address").text(client_apt + " " + street_route);
                    jQuery("#field_city").text(city);
                    jQuery("#field_city").val(city);
                    jQuery("#field_state").text(state);
                    jQuery("#field_state").val(state);
                });
            }
        }
    }
}